import { DataRow } from "../interfaces/DataRow";
import { ApiResponseError } from "../models/proxy-api-response-error";

/**
 * Maps plan data from PortaOne proxy API.
 * @param data 
 * @returns 
 */
export const mapPortaOnePlans = (products: any): DataRow[] => {
    return products.map(p => {
        const details = extractPlanDetailsFromEndUserName(p.end_user_name);
        let endUserDescription = JSON.parse(p.end_user_description)
        return {
            planId: p.i_product,
            plan_fee: details.price,
            carrier: "?",
            data: `${details.data} ${details.dataUnit}`,
            planTypeD: `${endUserDescription.TermDays}`,
            plintronPlanId: extractPlintronPlanIdFromProductNameIfExists(p.name),
            subscriptionId: p.i_subscription
        } as DataRow
    });
};

/**
 * Pulls the plintron plan id from the product name if it exists.
 * Its to handle service_i_product and recurring_i_product mappings on completeCheckout.
 * Example: 0_Plintron_plan_8001_re 
 * The id we need is 8001 for the backend on the completeCheckout step.
 * @param name 
 * @returns 
 */
const extractPlintronPlanIdFromProductNameIfExists = (name: string) => {
    const parts = name.split('_');
    if (parts.length !== 5) {
        return null;
    }
    // plintron id exists here parsing from this naming convention in portaone
    return +parts[parts.length - 2];
}

/**
 * Extracts plan details from end user name from this format: Price_Currency_DataAmount_DataUnit_Minutes_Interval
 * Example: 35_USD_8_GB_300_M
 * @param name 
 */
export const extractPlanDetailsFromEndUserName = (name: string): { price: number, currency: string, data: number, dataUnit: string, minutes: number, interval: string } => {
    const parts = name.split('_');
    if (parts.length < 6) {
        console.error(`Invalid EndUserName format. Should be Price_Currency_DataAmount_DataUnit_Minutes_Interval. Example: 35_USD_8_GB_300_M. Actual Value: ${name}`)
        return { price: 0, currency: '', data: 0, dataUnit: '', minutes: 0, interval: '' };
    }
    return { price: parseInt(parts[0]), currency: parts[1], data: parseInt(parts[2]), dataUnit: parts[3], minutes: parseInt(parts[4]), interval: parts[5] };
}

/**
 * Checks a credit card number for its type used with ProxyApi Portaone endpoints.
 * @param cc 
 * @returns 
 */
export const getCreditCardType = (cc: string) => {
    let amex = new RegExp('^3[47][0-9]{13}$');
    let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
    let mastercard = new RegExp('^5[1-5][0-9]{14}$');
    let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
    if (visa.test(cc)) {
        return 'VISA';
    }
    if (amex.test(cc)) {
        return 'American Express';
    }
    if (mastercard.test(cc) || mastercard2.test(cc)) {
        return 'MasterCard';
    }
    return 'VISA';
};

/**
 * Gets the i_payment_transaction from the from the URL parameters of the page location for US portaone purchases via PayPal.
 * @param location
 * @returns
 */
export const getPaymentTransactionIdFromUrl = (location: any): number => {
    var url = new URL(location.href);
    const paymentId = url.searchParams.get('i_payment_transaction') || null;
    return +paymentId;
}

/**
 * Parses known error codes into "user friendly" error messages.
 * @param error 
 * @returns 
 */
export const toFriendlyErrorMessage = (error: ApiResponseError): string => {
    switch (error.code) {
        case 'Server.Account.481':
        case 'Server.Account.438':
        case 'Server.Account.421':
            return 'Card declined.';
        case 'Server.Customer.duplicate_name':
            return 'Customer already exists.'
        case 'Server.Account.duplicate_id':
            return 'Account already exists.';
        case 'Server.Account.429':
            return 'Account error.';
        case 'Server.Account.account_info.i_product.not_main':
        case 'Server.Account.073':
        default:
            return 'Something went wrong.';
    }
}


/**
 * Validates an email for Portaone. Portaone self server web care login only allows certain characters. The rules for this are here: The value must contain only latin letters, digits, '-', '_', '@', and '.'
 * @param email 
 * @returns 
 */
export const validateEmail = (email: string): boolean => {
    // Regular expression pattern to match the email address format
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Match the email address against the pattern
    const match = pattern.test(email);

    // If the match is successful, the email address is valid
    return match;
}